import { NameSpace } from '../../const/name-space';
import { State } from '../../@types/store-types';
import { Tour } from '../../@types/tour-types';

export const getIsSingleTourDataLoading = (state: State): boolean => state[NameSpace.SingleTourData].isSingleTourDataLoading;
export const getTour = (state: State): Tour | null => state[NameSpace.SingleTourData].tour;
export const getUpdatedTourDescription = (state: State): string | null => state[NameSpace.SingleTourData].updatedTourDescription;

export const getIsSingleTourCreating = (state: State): boolean => state[NameSpace.SingleTourData].isSingleTourCreating;
export const getIsSingleTourUpdating = (state: State): boolean => state[NameSpace.SingleTourData].isSingleTourUpdating;
export const getIsSingleTourDescriptionUpdating = (state: State): boolean => state[NameSpace.SingleTourData].isSingleTourDescriptionUpdating;
