import { createSlice } from "@reduxjs/toolkit";
import { ToursListData } from "../../@types/store-types";
import { NameSpace } from "../../const/name-space";
import { fetchToursListAction } from "../api-actions";

const initialState: ToursListData = {
  toursList: [],
  isToursListDataLoading: false,
};

export const toursListData = createSlice({
  name: NameSpace.ToursListData,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchToursListAction.pending, (state) => {
        state.isToursListDataLoading = true;
      })
      .addCase(fetchToursListAction.fulfilled, (state, action) => {
        state.toursList = action.payload;
        state.isToursListDataLoading = false;
      })
      .addCase(fetchToursListAction.rejected, (state) => {
        state.isToursListDataLoading = false;
      });
  }
});
