export const Action = {
  LoadUserProfile: 'user/loadUserProfile',
  UserLogIn: 'user/login',
  UserLogOut: 'user/logout',

  FetchToursListAction: 'tours/fetch-list',
  FetchSingleTourAction: 'tours/fetch-one',
  CreateNewTourAction: 'tours/create',
  UpdateTourAction: 'tours/update',
  UpdateTourDescriptionAction: 'tours/update-description',

  FetchOneTourStepListAction: 'tour-steps/fetch-one',
  FetchTourStepsListAction: 'tour-steps/fetch-list',
  CreateNewTourStepAction: 'tour-steps/create',
  UpdateTourStepDestinationAction: 'tour-steps/update-destination',
  UpdateTourStepAction: 'tour-steps/update',

  FetchGuidesListAction: 'guides/fetch-list',
  CreateNewGuideAction: 'guides/create',

  UploadTourImageAction: 'upload/tour-image',
  UploadTourStepAudioAction: 'upload/tour-step-audio-image',

  FetchApplicationsListAction: 'applications/fetch-list',
  FetchSingleApplicationAction: 'applications/fetch-single-application',
  CreateNewApplicationAction: 'applications/create',
  CreateNewApplicationLocationAction: 'applications/create-application-location',
  UpdateApplicationLocationAction: 'applications/update-application-location',

  RedirectToRoute: 'app/redirectToRoute', // ??
  ValidateForm: 'app/validateForm', // ??
  DisplayError: 'app/displayError' // ??
} as const;
