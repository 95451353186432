export enum ApiRoute {
  Token = '/token',
  MyProfile = '/my_profile',
  Tour = '/tour',
  Tours = '/tours',
  ToursDescription = '/tours/description',
  ToursSteps = '/tours/steps',
  ToursStepsDestination = '/tours/steps/destination',
  Guides = '/guides',
  UploadTourImage = '/upload/tour/image',
  UploadTourStepAudio = '/upload/tour/step/audio',
  Applications = '/applications',
  ApplicationsLocation = '/applications/location'
}
