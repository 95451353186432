import { Button, Card, Form, Input, Select, Space, Table } from "antd";
import MyModal from "../../components/modal/modal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getGuidesList, getIsGuidesListDataLoading } from "../../store/guides-list-data/guides-list-data-selectors";
import { useEffect, useState } from "react";
import { createNewGuideAction, fetchGuidesListAction } from "../../store/api-actions";
import { showError, showInfo } from "../../services/notification";
import { ColumnsType } from "antd/es/table";
import { GUIDE_PERMISSIONS, Guide, guidePermissionAsStr } from "../../@types/guide-types";

import "./guides.scss"
import { getUserProfileData } from "../../store/user-process/user-process-selectors";

const GuidesPage = () => {
  const dispatch = useAppDispatch();
  const isGuidesListDataLoading = useAppSelector(getIsGuidesListDataLoading);
  const guidesList = useAppSelector(getGuidesList);

  const [openAddGuideDialog, setOpenCreateGuideDialog] = useState<boolean>(false);
  const [isGuideCreating, setIsGuideCreating] = useState<boolean>(false);

  const profileData = useAppSelector(getUserProfileData);

  const canCreateGuides = profileData && profileData.permissions.includes('CREATE_GUIDES')

  useEffect(() => {
    dispatch(fetchGuidesListAction());
  }, [dispatch]);

  const onCreateNewGuideButtonClick = () => {
    setOpenCreateGuideDialog(true);
  }

  const onCreateNewGuideCancelButtonClick = () => {
    setOpenCreateGuideDialog(false);
  }

  const onCreateNewGuideFormSubmit = (values: { email: string; name: string; password: string; permissions: string[]}) => {
    setIsGuideCreating(true);
    setTimeout(() => {
      dispatch(createNewGuideAction({name: values.name, email: values.email, password: values.password, permissions: values.permissions})).unwrap()
        .then(
          () => {
            setOpenCreateGuideDialog(false);
            setIsGuideCreating(false);
            showInfo("A guide was successfully created");
            dispatch(fetchGuidesListAction());
          },
          () => {
            setIsGuideCreating(false);
            showError("An error occurred while creating a new guide");
          }
        )
    }, 2000);
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const paginationConfig = {
    hideOnSinglePage: true,
  }

  const columns: ColumnsType<Guide> = [
    {
      key: 'guide_id',
      title: 'ID',
      dataIndex: 'guide_id',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
    },
  ];

  const guidePermissions = GUIDE_PERMISSIONS.map((permission) => (
    {
      value: permission,
      label: guidePermissionAsStr(permission)
    }
  ));

  return (
    <div className="guidesPage">
      <h1>Guides</h1>
      <MyModal open={openAddGuideDialog} onClose={onCreateNewGuideCancelButtonClick}>
        <h2>Create a new guide</h2>
        <Form
          {...formItemLayout}
          style={{ maxWidth: 600 }}
          onFinish={onCreateNewGuideFormSubmit}
          scrollToFirstError
        >
          <Form.Item label="Guide Name" name="name" rules={[{ required: true, message: 'Please, provide a name of a guide' }]}>
            <Input type="text" disabled={isGuideCreating}></Input>
          </Form.Item>
          <Form.Item label="Guide Email" name="email" rules={[
            { required: true, message: 'Please, provide an email of a guide' },
            { type: "email", message: 'The input is not valid E-mail!' },
          ]}>
            <Input type="email" disabled={isGuideCreating}></Input>
          </Form.Item>
          <Form.Item label="Guide Password" name="password" hasFeedback rules={[{ required: true, message: 'Please, provide a password of a guide' }]}>
            <Input.Password disabled={isGuideCreating}></Input.Password>
          </Form.Item>
          <Form.Item label="Confirm Password" name="password_confirmation" hasFeedback dependencies={['password']} rules={[{ required: true, message: 'Please, provide a password of a guide' }, ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The new password that you entered do not match!'));
            },
          }),]}>
            <Input.Password disabled={isGuideCreating}></Input.Password>
          </Form.Item>
          <Form.Item label="Permissions" name="permissions">
            <Select
              mode="multiple"
              //allowClear
              showSearch={false}
              placeholder="Select permissions"
              options={guidePermissions}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 8 }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={isGuideCreating}>
                Submit
              </Button>
              <Button onClick={onCreateNewGuideCancelButtonClick} disabled={isGuideCreating}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </MyModal>
      <Card className="criclebox" extra={
            canCreateGuides ? (
              <>
                <Button type="primary" onClick={onCreateNewGuideButtonClick}>Create a new guide...</Button>
              </>
            ) : ""
          }>
        <div className="table-responsive">
          <Table 
            rowKey="guide_id"
            loading={isGuidesListDataLoading}
            columns={columns}
            pagination={paginationConfig}
            dataSource={guidesList}
            className="ant-border-space"
          />
        </div>
      </Card>
    </div>
  )
}

export default GuidesPage;
