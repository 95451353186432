export type GuideId = string;
export type GuidePermission = string;

export const GUIDE_PERMISSIONS: GuidePermission[] = [
  'READ_ALL_TOURS',
  'EDIT_ALL_TOURS',
  'CREATE_TOURS',
  'READ_GUIDES',
  'CREATE_GUIDES',
  'EDIT_GUIDES',
]

export const guidePermissionAsStr = (permission: GuidePermission): string =>  {
  switch(permission) {
    case 'READ_ALL_TOURS': {
      return "Read All Tours";
    }
    case 'EDIT_ALL_TOURS': {
      return "Edit All Tours";
    }
    case 'CREATE_TOURS': {
      return "Create Tours";
    }
    case 'READ_GUIDES': {
      return "Read Guides";
    }
    case 'CREATE_GUIDES': {
      return "Create Guides";
    }
    case 'EDIT_GUIDES': {
      return "Edit Guides";
    }
    default: {
      return permission;
    }
  }
}

export type Guide = {
  guide_id: GuideId;
  name: string;
  email: string;
  permissions: GuidePermission[];
};
