import { Button, Form, Space } from "antd";
import "./edit-tour-description.scss"
import TextArea from "antd/es/input/TextArea";

export type EditTourDescriptionProps = {
  isDataUploading: boolean,
  onSubmit?: (values: any) => void,
  onCancel?: () => void,
  description: string
};

const EditTourDescriptionForm = ({onSubmit, isDataUploading = true, onCancel, description} : EditTourDescriptionProps) => {
  return (
    <Form onFinish={onSubmit} className="create-update-tour-form">
      <Form.Item initialValue={description} label="Description" name="description">
        <TextArea disabled={isDataUploading} autoSize={{ minRows: 3, maxRows: 10 }}></TextArea>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button onClick={onCancel} disabled={isDataUploading}>Cancel</Button>
          <Button loading={isDataUploading} htmlType="submit" type="primary">{isDataUploading? "Updating..." : "Update"}</Button>
        </Space>
      </Form.Item>          
    </Form>
  )
};

export default EditTourDescriptionForm;
