import { Button, Form, Input } from "antd"

import "./login.scss"
import { getIsLoginLoading } from "../../store/user-process/user-process-selectors";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loadUserProfileAction, loginAction } from "../../store/api-actions";

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoginLoading);

  const onFinish = (values: { email: string; password: string }) => {
    dispatch(loginAction({ email: values.email, password: values.password})).unwrap().then(() => {
      // TODO: Is it OK???
      dispatch(loadUserProfileAction());
    });
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  //if (authToken) {
  //  return <Navigate to="/" />;
  //}

  return (
    <div className="login-form-wrapper">
      <Form
        {...formLayout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input type="email" disabled={isLoading}></Input>
        </Form.Item>

        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password disabled={isLoading}></Input.Password>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button loading={isLoading} htmlType="submit" type="primary">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
