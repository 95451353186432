import { createSlice } from "@reduxjs/toolkit";
import { GuidesListData } from "../../@types/store-types";
import { NameSpace } from "../../const/name-space";
import { fetchGuidesListAction } from "../api-actions";

const initialState: GuidesListData = {
  guidesList: [],
  isGuidesListDataLoading: false,
};

export const guidesListData = createSlice({
  name: NameSpace.GuidesListData,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGuidesListAction.pending, (state) => {
        state.isGuidesListDataLoading = true;
      })
      .addCase(fetchGuidesListAction.fulfilled, (state, action) => {
        state.guidesList = action.payload;
        state.isGuidesListDataLoading = false;
      })
      .addCase(fetchGuidesListAction.rejected, (state) => {
        state.isGuidesListDataLoading = false;
      });
  }
});
