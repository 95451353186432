import { createSlice } from "@reduxjs/toolkit";
import { TourStepsListData } from "../../@types/store-types";
import { NameSpace } from "../../const/name-space";
import { fetchTourStepsListAction } from "../api-actions";

const initialState: TourStepsListData = {
  tourStepsList: [],
  isTourStepsListDataLoading: false,
};

export const tourStepsListData = createSlice({
  name: NameSpace.TourStepsListData,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTourStepsListAction.pending, (state) => {
        state.isTourStepsListDataLoading = true;
      })
      .addCase(fetchTourStepsListAction.fulfilled, (state, action) => {
        state.tourStepsList = action.payload;
        state.isTourStepsListDataLoading = false;
      })
      .addCase(fetchTourStepsListAction.rejected, (state) => {
        state.isTourStepsListDataLoading = false;
      });
  }
});
