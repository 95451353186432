import { createSlice } from "@reduxjs/toolkit";
import { TourStepData } from "../../@types/store-types";
import { NameSpace } from "../../const/name-space";
import { fetchOneTourStepAction, updateTourStepAction, updateTourStepDestinationAction } from "../api-actions";

const initialState: TourStepData = {
  tourStep: null,
  updatedTourDestination: null,
  isTourStepDataLoading: false,
  isTourStepUpdating: false,
  isTourStepDestinationUpdating: false,
};


export const tourStepData = createSlice({
  name: NameSpace.TourStepData,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchOneTourStepAction.pending, (state) => {
        state.tourStep = null;
        state.isTourStepDataLoading = true;
      })
      .addCase(fetchOneTourStepAction.fulfilled, (state, action) => {
        state.tourStep = action.payload;
        state.isTourStepDataLoading = false;
      })
      .addCase(fetchOneTourStepAction.rejected, (state) => {
        state.tourStep = null;
        state.isTourStepDataLoading = false;
      })
      .addCase(updateTourStepAction.pending, (state) => {
        state.isTourStepUpdating = true;
      })
      .addCase(updateTourStepAction.fulfilled, (state, action) => {
        state.isTourStepUpdating = false;
        state.tourStep = action.payload;
      })
      .addCase(updateTourStepAction.rejected, (state) => {
        state.isTourStepUpdating = false;
      })
      .addCase(updateTourStepDestinationAction.pending, (state) => {
        state.isTourStepDestinationUpdating = true;
      })
      .addCase(updateTourStepDestinationAction.fulfilled, (state, action) => {
        state.isTourStepDestinationUpdating = false;
        state.updatedTourDestination = action.payload;
      })
      .addCase(updateTourStepDestinationAction.rejected, (state) => {
        state.isTourStepDestinationUpdating = false;
      })
  }
});
