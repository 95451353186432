import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getIsTourStepDataLoading, getTourStep } from "../../store/tour-step-data/tour-step-data-selectors";
import { Link, useParams } from "react-router-dom";
import { fetchOneTourStepAction, fetchSingleTourAction, updateTourStepAction, updateTourStepDestinationAction, uploadTourStepAudioAction } from "../../store/api-actions";
import { Button, Card, Col, Image, Row, Space, Spin, Upload, UploadProps } from "antd";
import Title from "antd/es/typography/Title";
import { getIsSingleTourDataLoading, getTour } from "../../store/tour-data/tour-data-selectors";
import { tourStepTypeAsStr } from "../../@types/tour-types";
import { InboxOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import MyModal from "../../components/modal/modal";
import { AudioRecorder } from "react-audio-voice-recorder";
import { showError, showInfo } from "../../services/notification";
import './tourstep.scss'
import { RcFile } from "antd/es/upload";
import EditTourDestinationForm from "../../components/forms/tour/edit-tour-destination/EditTourDestination";
import EditTourStepForm, { EditTourStepFormData } from "../../components/forms/tour/edit-tour-step/EditTourStepForm";
import ReactAudioPlayer from "react-audio-player";

type HeaderTableCellProps = {
  title: string,
  children: any
};

const { Dragger } = Upload;

const HeaderTableCell = ({title, children} : HeaderTableCellProps) => (
  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
    <Space direction="vertical">
      <Title level={4}>{title}</Title>
      {children}
    </Space>
  </Col>
)

type TourStepImageProps = {
  imageUrl: string,
  height?: number,
}

const TourStepImage = ({imageUrl, height}: TourStepImageProps) => {
  const DEFAULT_HEIGHT = 100;
  const imageHeight = height || DEFAULT_HEIGHT;

  return (
  <Image src={imageUrl} height={imageHeight} preview={
    {
      toolbarRender: (
        _,
        {
          transform: { scale },
          actions: {onZoomOut, onZoomIn}
        }
      ) => (
        <Space size={12} className="page-view-tour-image-toolbar-wrapper">
          <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
        </Space>
      )
    }
  } />
  )
};

const TourStepPage = () => {
  const dispatch = useAppDispatch();
  const isTourStepDataLoading = useAppSelector(getIsTourStepDataLoading);
  const tourStep = useAppSelector(getTourStep);
  const isSingleTourDataLoading = useAppSelector(getIsSingleTourDataLoading);
  const tour = useAppSelector(getTour);

  const [isUploadAudioDoalogOpened, setIsUploadAudioDoalogOpened] = useState<boolean>(false);
  const [newAudioUrl, setNewAudioUrl] = useState<string | null>(null);
  const [isFileDragged, setIsFileDragged] = useState<boolean>(false);
  const [isInUploadingTourStepAudioMode, setIsInUploadingTourStepAudioMode] = useState<boolean>(false);
  const [isTourStepDestinationFormOpen, setIsTourStepDestinationFormOpen] = useState<boolean>(false);
  const [isTourStepDestinationDataUploading, setIsTourStepDestinationDataUploading] = useState<boolean>(false);
  const [newTourStepDestination, setNewTourStepDestination] = useState<string | null>(null);
  const [isEditTourStepDialogOpen, setIsEditTourStepDialogOpen] = useState<boolean>(false);
  const [isEditTourStepDataUploading, setIsEditTourStepDataUploading] = useState<boolean>(false);
  
  const isLoading = isTourStepDataLoading || !tourStep || isSingleTourDataLoading || !tour;
  const audioUrl: string | null = !!tourStep ? (newAudioUrl || tourStep.audio_url) : null;
  const tourStepDestination: string = newTourStepDestination || (tourStep && tourStep.location_text ? tourStep.location_text : "");

  const { tourId } = useParams();
  const { tourStepId } = useParams();

  const gotoStepType = 'GO_TO';
  const isGoToStepType = tourStep && tourStep.tour_step_type === gotoStepType;

  const viewStepType = 'VIEW';
  const isViewStepType = tourStep && tourStep.tour_step_type === viewStepType;

  useEffect(() => {
    dispatch(fetchOneTourStepAction({tourId: tourId!, tourStepId: tourStepId!}));
    dispatch(fetchSingleTourAction(tourId!));
  }, [dispatch, tourId, tourStepId]);

  const draggerProps: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: "audio/*",
    //listType: "picture-card",
    maxCount: 1,
    customRequest: ({file, onSuccess, onError}) => {
      setIsFileDragged(false);
      setIsInUploadingTourStepAudioMode(true);
      dispatch(uploadTourStepAudioAction({tourId: tourId!, tourStepId: tourStepId!, audio: file as RcFile})).unwrap()
        .then((newUrl: string) => {
          setNewAudioUrl(newUrl);
        }, () => {
          showError("Could not upload audio. Please, try again later")
        })
        .catch(() => {
          showError("Could not upload audio. Please, try again later.")
        })
        .finally(() => {
          setIsInUploadingTourStepAudioMode(false);
          setIsUploadAudioDoalogOpened(false);
        })
    },
  };

  const onAudioRecoded = (blob: Blob) => {
    dispatch(uploadTourStepAudioAction({tourId: tourId!, tourStepId: tourStepId!, audio: new File([blob], "file.webm", {type: "audio/webm"})})).unwrap()
      .then((newUrl: string) => {
        showInfo("Tour Step audio was successfully uploaded");
        setNewAudioUrl(newUrl);
      }, () => {
        showError("Could not upload audio. Please, try again later")
      })
      .catch((error) => {
        showError("Could not upload audio. Please, try again later.")
      });
    setIsUploadAudioDoalogOpened(false);
  };

  const onEditTourStepDestinationEditFormSubmit = (newDestination: string) => {
    setIsTourStepDestinationDataUploading(true);
    window.setTimeout(() => {
      dispatch(updateTourStepDestinationAction({destination: newDestination, tourId: tourId!, tourStepId: tourStepId!})).unwrap()
      .then((receivedDestination: string) => {
        setNewTourStepDestination(receivedDestination);
      }, () => {
        showError("Could not update destination. Please, try again later")
      })
      .catch(() => {
        showError("Could not update destination. Please, try again later")
      })
      .finally(() => {
        setIsTourStepDestinationDataUploading(false);
        setIsTourStepDestinationFormOpen(false);
      });
    }, 2000);
  };

  const onEditTourStepFormSubmit = (formData: EditTourStepFormData) => {
    console.log(formData);
    setIsEditTourStepDataUploading(true);
    window.setTimeout(() => {
      dispatch(updateTourStepAction({tourId: tourId!, tourStepId: tourStepId!, tourStepData: {
        name: formData.name,
        latitude: formData.latitude || undefined,
        longitude: formData.longitude || undefined,
        hide_photo_captions: formData.hide_photo_captions
      }}))
      .unwrap()
      .catch(() => {
        showError("Could not update tour step. Please, try again later")
      })
      .finally(() => {
        setIsEditTourStepDataUploading(false);
        setIsEditTourStepDialogOpen(false);
      })
    }, 1000);
  }

  return (
    <>
    <MyModal open={isUploadAudioDoalogOpened} onClose={() => {setIsUploadAudioDoalogOpened(false)}}>
      <h2>Record audio</h2>
      <AudioRecorder onRecordingComplete={onAudioRecoded} audioTrackConstraints={{
        noiseSuppression: true,
        echoCancellation: true,
      }} />
      {isInUploadingTourStepAudioMode ? (<>Uploading...</>) : (
      <div className="tour-step-audio-upload">
      <Dragger disabled={isInUploadingTourStepAudioMode} {...draggerProps} className={isFileDragged ? "file-dragger file-dragging" : "file-dragger"}>
        <div onDragOver={() => {setIsFileDragged(true)}} onDragLeave={() => setIsFileDragged(false)}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag audio to this area to upload</p>
        </div>
      </Dragger>
      </div>)}
    </MyModal>
    <MyModal open={isTourStepDestinationFormOpen} onClose={() => setIsTourStepDestinationFormOpen(false)}>
      <h2>Edit Directons</h2>
      <EditTourDestinationForm
        onCancel={() => setIsTourStepDestinationFormOpen(false)}
        onSubmit={onEditTourStepDestinationEditFormSubmit}
        isDataUploading={isTourStepDestinationDataUploading}
        destination={tourStepDestination}
      />
    </MyModal>
    <MyModal open={isEditTourStepDialogOpen} onClose={() => setIsEditTourStepDialogOpen(false)}>
      <h2>Edit Tour Step</h2>
      <EditTourStepForm
        onSubmit={onEditTourStepFormSubmit}
        isDataUploading={isEditTourStepDataUploading}
        onCancel={() => setIsEditTourStepDialogOpen(false)}
        tourStepData={tourStep ? {
          id: tourStep.tour_step_id,
          name: tourStep.name,
          type: tourStep.tour_step_type,
          latitude: tourStep.latitude,
          longitude: tourStep.longitude,
          hide_photo_captions: tourStep.hide_photo_captions
        } : undefined}
      />
    </MyModal>
    <span>{isLoading ? "Loading..." : <Link to={`/tours/${tourId}`} style={{ textDecoration: "none" }}>{tour.name}</Link>}</span>
    <h1>{isLoading ? "Loading..." : tourStep.name}</h1>
    <div className="tour-page">
      { isLoading ? (
        <Spin/>
      ) : (
        <>
          <Card className="circlebox mb-20" bordered={false}>
            <Row gutter={[24, 10]}>
              <HeaderTableCell title="Information">
                <span><b>ID: </b>{tourStep && tourStep.tour_step_id}</span>
                <span><b>Name: </b>{tourStep && tourStep.name}</span>
                <span><b>Type: </b>{tourStep && tourStepTypeAsStr(tourStep.tour_step_type)}</span>
                {isGoToStepType ? (<span><b>Coordinates: </b> <Link to={`https://www.google.com/maps/place/${tourStep.latitude},${tourStep.longitude}`} target="_blank">{tourStep.latitude}, {tourStep.longitude}</Link></span>) : ""}
                {tourStep && tourStep.skip_to_step_id ? (<span><b>Skip to step Id: </b>{tourStep.skip_to_step_id}</span>) : ""}
                {tourStep ? <span><b>Hide photo captions: </b>{tourStep.hide_photo_captions ? "Yes" : "No"}</span> : ""}
                <Button onClick={() => setIsEditTourStepDialogOpen(true)}>Edit...</Button>
              </HeaderTableCell>
              <HeaderTableCell title="Directions">
                {isGoToStepType ? (<>{tourStepDestination}<Button onClick={() => setIsTourStepDestinationFormOpen(true)}>Edit...</Button></>) : ""}
                {isGoToStepType && audioUrl ? (<><h3>Audio</h3><ReactAudioPlayer src={audioUrl} controls /></>) : ""}
                <Space>
                  <Button onClick={() => {setIsUploadAudioDoalogOpened(true)}}>Upload audio...</Button>
                  <Button onClick={() => {setIsUploadAudioDoalogOpened(true)}}>Delete audio...</Button>
                </Space>
              </HeaderTableCell>
              <HeaderTableCell title="Tour Step Photo">
                {tourStep && tourStep.location_photo_url ? <TourStepImage imageUrl={tourStep.location_photo_url} height={200} /> : ""}
                {tourStep.tour_step_photo_urls.map((tourStepPhotoUrl, index) => (
                  <div key={index} className="tour-step-photo"><TourStepImage imageUrl={tourStepPhotoUrl} /></div>
                ))}
              </HeaderTableCell>
            </Row>
          </Card>
          { isViewStepType ? (
          <Card className="circlebox mb-20" bordered={false} title="Text & Audio">
            <Row gutter={[24, 10]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <Space direction="vertical">
                  <span>AUDIO</span>
                  {tourStep.audio_url ? <audio src={tourStep.audio_url} controls></audio> : ""}
                </Space>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                {tourStep.texts.map((text, index) => (<><b>{index + 1}</b><div key={index}>{text}</div></>))}
              </Col>
            </Row>
          </Card>
          ) : ""}
        </>
      )}
    </div>
    </>
  )
}

export default TourStepPage;
