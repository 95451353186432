import { Link, NavLink } from "react-router-dom";

import "./sidebar.scss";
import { Menu } from "antd";
import { AppstoreOutlined, DashboardFilled, FlagFilled, UserOutlined } from "@ant-design/icons";

type SidebarProps = {
  onLinkClick?: () => void;
};

const Sidebar = ({onLinkClick}: SidebarProps) => {

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">Walk With Fun</span>
        </Link>
      </div>
      <hr />
      <Menu
        mode="inline"
        theme="light"
        style={{ width: "100%" }}
        className="sidebar-menu"
        onClick={onLinkClick}
      >
        <Menu.Item
          key="dashboard"
          icon={<DashboardFilled className="menu-item-icon" />}
          className="menu-item"
        >
          <NavLink to="/">Dashboard</NavLink>
        </Menu.Item>
        <Menu.Item
          key="apps"
          icon={<AppstoreOutlined className="menu-item-icon" />}
          className="menu-item"
        >
          <NavLink to="/apps">Apps</NavLink>
        </Menu.Item>
        <Menu.Item
          key="tours"
          icon={<FlagFilled className="menu-item-icon" />}
          className="menu-item"
        >
          <NavLink to="/tours">Tours</NavLink>
        </Menu.Item>
        <Menu.Item
          key="guides"
          icon={<UserOutlined className="menu-item-icon" />}
          className="menu-item"
        >
          <NavLink to="/guides">Guides</NavLink>
        </Menu.Item>
      </Menu>

      <div className="bottom">
        Bottom
      </div>
    </div>
  );
};

export default Sidebar;