export type TourId = string;
export type TourStepId = string;
export type TourStepType = string;
export type TourLanguage = string;
export type TourUsefulInfoPlaceType = string;

export type TourUsefulInfoPlace = {
  place_type: TourUsefulInfoPlaceType;
  name: string;
  description: string;
  link?: string;
}

export type TourUsefulInfo = {
  places: TourUsefulInfoPlace[];
}

export type Tour = {
  tour_id: TourId;
  name: string;
  guide_id: string;
  tour_photo_url?: string;
  language: string;
  duration_in_minutes: number;
  length_in_meters: number;
  last_location_same: boolean;
  sights: string[];
  description: string;
  available_from?: Date;
  available_until?: Date;
  active: boolean;
  last_free_step_id?: number;
  last_free_step_name?: string;
  price_eur_cents?: number;
  allow_leaving_tips?: boolean;
  tour_useful_info?: TourUsefulInfo;
};

export type TourStep = {
  tour_step_id: TourStepId;
  tour_step_type: TourStepType;
  name: string;
  texts: string[];
  tour_step_photo_urls: string[];
  data_validation_errors: string[];
  latitude: number | null;
  longitude: number | null;
  location_photo_url: string | null;
  location_text: string | null;
  audio_url: string | null;
  skip_to_step_id: TourStepId | null;
  hide_photo_captions: boolean;
};

export const TOUR_STEP_TYPES: TourStepType[] = [
  'GO_TO',
  'QUIZ',
  'VIEW',
  'SKIP',
];

export const tourStepTypeAsStr = (tourStepType: TourStepType): string =>  {
  switch(tourStepType) {
    case 'GO_TO': {
      return "Go To Location";
    }
    case 'QUIZ': {
      return "Quiz";
    }
    case 'VIEW': {
      return "View";
    }
    case 'SKIP': {
      return "Skip";
    }
    default: {
      return tourStepType;
    }
  }
};

export const TOUR_LANGUAGES: TourLanguage[] = [
  'ENGLISH',
  'GERMAN',
  'FRENCH',
  'SPANISH',
  'RUSSIAN',
  'CHINESE',
  'ITALIAN',
];

export const tourLanguagesAsStr = (tourLanguage: TourLanguage): string =>  {
  switch(tourLanguage) {
    case 'ENGLISH': {
      return "English";
    }
    case 'GERMAN': {
      return "German";
    }
    case 'FRENCH': {
      return "French";
    }
    case 'SPANISH': {
      return "Spanish";
    }
    case 'RUSSIAN': {
      return "Russian";
    }
    case 'CHINESE': {
      return "Chinise";
    }
    case 'ITALIAN': {
      return "Italian";
    }
    default: {
      return tourLanguage;
    }
  }
};

export const TOUR_USEFUL_INFO_PLACE_TYPES: TourUsefulInfoPlaceType[] = [
  'RESTAURANT',
  'COFFEE',
  'BAR',
  'MUSEUM'
];

export const tourUsefulInfoPlaceTypeAsStr = (tourUsefultInfoPlaceType: TourUsefulInfoPlaceType): string => {
  switch(tourUsefultInfoPlaceType) {
    case 'RESTAURANT': {
      return "Restaurant"
    }
    case 'COFFEE': {
      return "Coffee"
    }
    case 'BAR': {
      return "Bar"
    }
    case 'MUSEUM': {
      return "Museum"
    }
    default: {
      return tourUsefultInfoPlaceType;
    }
  }
};
