import { combineReducers } from '@reduxjs/toolkit';
import { NameSpace } from '../const/name-space';
import { userProcessData } from './user-process/user-process';
import { toursListData } from './tours-list-data/tours-list-data';
import { guidesListData } from './guides-list-data/guides-list-data';
import { tourStepsListData } from './tour-steps-list-data/tour-steps-list-data';
import { singleTourData } from './tour-data/tour-data';
import { tourStepData } from './tour-step-data/tour-step-data';
import { applicationsListData } from './applications-list-data/applications-list-data';
import { applicationData } from './application-data/application-data';

export const rootReducer = combineReducers({
  [NameSpace.User]: userProcessData.reducer,
  [NameSpace.SingleTourData]: singleTourData.reducer,
  [NameSpace.ToursListData]: toursListData.reducer,
  [NameSpace.TourStepsListData]: tourStepsListData.reducer,
  [NameSpace.TourStepData]: tourStepData.reducer,
  [NameSpace.GuidesListData]: guidesListData.reducer,
  [NameSpace.ApplicationsListData]: applicationsListData.reducer,
  [NameSpace.ApplicationData]: applicationData.reducer,
});
