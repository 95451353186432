import { Button, Card, Table } from "antd"
import { getIsToursListDataLoading, getToursList } from "../../store/tours-list-data/tours-list-data-selectors";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createNewTourAction, fetchToursListAction } from "../../store/api-actions";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { Tour } from "../../@types/tour-types";

import "./tours.scss"
import MyModal from "../../components/modal/modal";
import { showError, showInfo } from "../../services/notification";
import { Link } from "react-router-dom";
import { getUserProfileData } from "../../store/user-process/user-process-selectors";
import CreateTourForm from "../../components/forms/tour/create-tour/CreateTourForm";
import { getIsSingleTourCreating } from "../../store/tour-data/tour-data-selectors";

const ToursPage = () => {
  const dispatch = useAppDispatch();
  const isToursListDataLoading = useAppSelector(getIsToursListDataLoading);
  const toursList = useAppSelector(getToursList);
  const isSingleTourCreating = useAppSelector(getIsSingleTourCreating);

  const [openAddTourDialog, setOpenCreateTourDialog] = useState<boolean>(false);
  const profileData = useAppSelector(getUserProfileData);

  const canCreateTours = profileData && profileData.permissions.includes('CREATE_TOURS')

  const onCreateNewTourButtonClick = () => {
    setOpenCreateTourDialog(true);
  }

  const onCreateNewTourCancelButtonClick = () => {
    setOpenCreateTourDialog(false);
  }

  const onCreateNewTourFormSubmit = (values: { name: string; language: string;}) => {
    setTimeout(() => {
      dispatch(createNewTourAction({
        name: values.name,
        language: values.language
      })).unwrap().then(
        () => {
          setOpenCreateTourDialog(false);
          showInfo("A tour was successfully created");
          dispatch(fetchToursListAction());
        },
        () => {
          showError("An error occurred while creating a new tour");
        }
      )
    }, 2000);
  }

  useEffect(() => {
    dispatch(fetchToursListAction());
  }, [dispatch]);

  const paginationConfig = {
    hideOnSinglePage: true,
  }

  const columns: ColumnsType<Tour> = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      render: (text, { tour_id }) => <Link to={`/tours/${tour_id}`} style={{ textDecoration: "none" }}>{text}</Link>,
    },
    {
      key: 'guide_name',
      title: 'Guide',
      dataIndex: 'guide_name',
    },
  ];

  return (
    <>
      <h1>Tours</h1>
      <MyModal open={openAddTourDialog} onClose={onCreateNewTourCancelButtonClick}>
        <h2>Create a new tour</h2>
        <CreateTourForm
          isDataUploading={isSingleTourCreating}
          onSubmit={onCreateNewTourFormSubmit}
          onCancel={onCreateNewTourCancelButtonClick}
        />
      </MyModal>
      <div className="toursPage">
        <Card className="criclebox" extra={
            canCreateTours ? (
              <>
                <Button type="primary" onClick={onCreateNewTourButtonClick}>Create a new tour...</Button>
              </>
            ) : ""}>
          <div className="table-responsive">
            <Table rowKey="tour_id" loading={isToursListDataLoading} columns={columns} pagination={paginationConfig} dataSource={toursList}></Table>
          </div>
        </Card>
      </div>
    </>
  )
}

export default ToursPage;
