import { createSlice } from "@reduxjs/toolkit";
import { ApplicationData } from "../../@types/store-types";
import { NameSpace } from "../../const/name-space";
import { fetchSingleApplicationAction } from "../api-actions";

const initialState: ApplicationData = {
  application: null,
  isApplicationDataLoading: false,
};

export const applicationData = createSlice({
  name: NameSpace.ApplicationData,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSingleApplicationAction.pending, (state) => {
        state.application = null;
        state.isApplicationDataLoading = true;
      })
      .addCase(fetchSingleApplicationAction.fulfilled, (state, action) => {
        state.application = action.payload;
        state.isApplicationDataLoading = false;
      })
      .addCase(fetchSingleApplicationAction.rejected, (state) => {
        state.isApplicationDataLoading = false;
      });
  }
});
