import { Spin } from 'antd';
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';

import Home from '../../pages/home/Home';
import ToursPage from '../../pages/tours/Tours';
import { LoginPage } from '../../pages/login/Login';
import { NotFoundPage } from '../../pages/system/not-found/NotFound';
import { LogoutPage } from '../../pages/system/logout/Logout';
import { useAppSelector } from '../../hooks';
import { getAuthorizationStatus } from '../../store/user-process/user-process-selectors';
import { AuthorizationStatus } from '../../const/authorization-status';
import { ToastContainer } from 'react-toastify';
import GuidesPage from '../../pages/guides/Guides';
import TourPage from '../../pages/tour/Tour';

import 'react-toastify/dist/ReactToastify.css';
import Page from '../page/Page';
import TourStepPage from '../../pages/tour-step/TourStep';
import ApplicationsPage from '../../pages/applications/Applications';
import Application from '../../pages/application/Application';

export const App = () => {
  const authorizationStatus = useAppSelector(getAuthorizationStatus);

  const PageOutlet = () => {
    return (
      <Page>
        <Outlet />
      </Page>
    )
  }

  return (
    <div className="app">
      <ToastContainer />
      {
        authorizationStatus === AuthorizationStatus.Unknown ? (
          <Spin />
        ) : (
          <BrowserRouter>
            {
              authorizationStatus === AuthorizationStatus.Auth ? (
                <>
                  <Routes>
                    <Route path="/login" element={<Navigate to="/" />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route element={<PageOutlet/>}>
                      <Route index element={<Home />} />
                      <Route path="/tours">
                        <Route index element={<ToursPage />} />
                        <Route path=":tourId">
                          <Route index element={<TourPage />} />
                          <Route path="steps/:tourStepId" element={<TourStepPage />} />
                        </Route>
                      </Route>
                      <Route path="/apps">
                        <Route index element={<ApplicationsPage />} />
                        <Route path=":appId" element={<Application />} />
                      </Route>
                      <Route path="/guides" element={<GuidesPage />} />
                      <Route path="/*" element={<NotFoundPage />} />
                    </Route>
                  </Routes>
                </>
              ) : (
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/*" element={<Navigate to='/login' />} />
                </Routes>
              )
            }
          </ BrowserRouter>
        )
      }
    </div>
  );
}
