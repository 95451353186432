import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getApplicationsList, getIsApplicationsListDataLoading } from "../../store/applications-list-data/applications-list-data-selectors";
import "./applications.scss"
import { getUserProfileData } from "../../store/user-process/user-process-selectors";
import { createNewApplicationAction, fetchApplicationsListAction } from "../../store/api-actions";
import { Button, Card, Form, Input, Space, Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { Application } from "../../@types/application-types";
import MyModal from "../../components/modal/modal";
import { showError, showInfo } from "../../services/notification";
import { Link } from "react-router-dom";

const ApplicationsPage = () => {
  const dispatch = useAppDispatch();
  const isApplicationsListDataLoading = useAppSelector(getIsApplicationsListDataLoading);
  const applicationsList = useAppSelector(getApplicationsList);

  const [openCreateApplicationDialog, setOpenCreateApplicationDialog] = useState<boolean>(false);
  const [isApplicationCreating, setIsApplicationCreating] = useState<boolean>(false);

  const profileData = useAppSelector(getUserProfileData);
  const canCreateApplications = profileData && profileData.permissions.includes('CREATE_APPLICATIONS')

  useEffect(() => {
    dispatch(fetchApplicationsListAction());
  }, [dispatch]);

  const onCreateNewApplicationButtonClick = () => {
    setOpenCreateApplicationDialog(true);
  };

  const onCreateNewApplicationCancelButtonClick = () => {
    setOpenCreateApplicationDialog(false);
  };

  const onCreateNewApplicationFormSubmit = (values: { name: string; root_location_name: string;}) => {
    setIsApplicationCreating(true);
    setTimeout(() => {
      dispatch(createNewApplicationAction({name: values.name, root_location_name: values.root_location_name})).unwrap()
        .then(
          () => {
            setOpenCreateApplicationDialog(false);
            setIsApplicationCreating(false);
            showInfo("An application was successfully created");
            dispatch(fetchApplicationsListAction());
          },
          () => {
            setIsApplicationCreating(false);
            showError("An error occurred while creating a new guide");
          }
        )
    }, 2000);
  };

  const columns: ColumnsType<Application> = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      render: (text, { app_id }) => <Link to={`/apps/${app_id}`} style={{ textDecoration: "none" }}>{text}</Link>,
    },
    {
      key: 'guide_name',
      title: 'Guide',
      dataIndex: 'guide_name',
    },
    {
      key: 'root_location',
      title: 'Root Location',
      dataIndex: 'root_location',
      render: (text, { root_location }) => root_location.name,
    },
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const telegramIntegrationCard = (
    <>
      <span><b>Telegram Bot Token: </b>111</span>
    </>
    );  

  return (
    <div className="applications-page">
      <h1>Applications</h1>
      <MyModal open={openCreateApplicationDialog} onClose={onCreateNewApplicationCancelButtonClick}>
        <h2>Create a new application</h2>
        <Form
          {...formItemLayout}
          style={{ maxWidth: 600 }}
          onFinish={onCreateNewApplicationFormSubmit}
          scrollToFirstError
        >
          <Form.Item label="Application Name" name="name" rules={[{ required: true, message: 'Please, provide a name of an application' }]}>
            <Input type="text" disabled={isApplicationCreating}></Input>
          </Form.Item>
          <Form.Item label="Root Location Name" name="root_location_name" rules={[{ required: true, message: 'Please, provide a name of a root location name' }]}>
            <Input type="text" disabled={isApplicationCreating}></Input>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 8 }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={isApplicationCreating}>
                Submit
              </Button>
              <Button onClick={onCreateNewApplicationCancelButtonClick} disabled={isApplicationCreating}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </MyModal>
      <Card className="criclebox" extra={
            canCreateApplications ? (
              <>
                <Button type="primary" onClick={onCreateNewApplicationButtonClick}>Create a new application...</Button>
              </>
            ) : ""
          }>
        <div className="table-responsive">
          <Table 
            rowKey="application_id"
            loading={isApplicationsListDataLoading}
            columns={columns}
            pagination={{hideOnSinglePage: true}}
            dataSource={applicationsList}
            className="ant-border-space"
          />
        </div>
      </Card>
      <Card className="circlebox mb-20" bordered={false} title="Integrations">
        <Tabs type="card" items={[{
          label: 'Telegram',
          key: 'telegram',
          children: telegramIntegrationCard,
        }, {
          label: 'SDKs',
          key: 'sdk'
        }]}></Tabs>
      </Card>
    </div>
  )
};

export default ApplicationsPage;
