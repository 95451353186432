import { Modal } from 'antd';
import './modal.scss'


type ModalProps = {
  children: any;
  open?: boolean;
  onClose?: () => void;
}

const MyModal = ({children, open, onClose}: ModalProps) => {
  return (
    <Modal open={open || false} onCancel={onClose} onOk={onClose} footer={[]} className="my-modal-dialog">
      {children}
    </Modal>
  )
}

export default MyModal;
