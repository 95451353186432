import React from "react";
import { useAppDispatch } from "../../../hooks";
import { logoutAction } from "../../../store/api-actions";
import { dropToken } from "../../../services/token";

export const LogoutPage = () => {
  const [logofText, setLogofText] = React.useState<string>("Logging off...");
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(logoutAction()).unwrap().then(
      () => {
        dropToken();
      },
      () => {
        setTimeout(() => {
          window.location.replace('/');
        }, 5000);
        setLogofText("A error occurred while loggin you off. You will be redirected to the main page in 5 seconds...");
      }
    );
  }, [dispatch]);

  return (
    <div>
      {logofText}
    </div>
  )
}
