import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getIsTourStepsListDataLoading, getTourStepsList } from "../../store/tour-steps-list-data/tour-steps-list-data-selectors";
import { useEffect, useState } from "react";
import { createNewTourStepAction, fetchSingleTourAction, fetchTourStepsListAction, updateTourAction, updateTourDescriptionAction, uploadTourImageAction } from "../../store/api-actions";
import Table, { ColumnsType } from "antd/es/table";
import { TOUR_STEP_TYPES, TourStep, tourLanguagesAsStr, tourStepTypeAsStr } from "../../@types/tour-types";
import { Button, Card, Col, Form, Input, Row, Select, Space, Spin, Upload, UploadProps, Image, Alert } from "antd";
import { getIsSingleTourDataLoading, getIsSingleTourDescriptionUpdating, getIsSingleTourUpdating, getTour, getUpdatedTourDescription } from "../../store/tour-data/tour-data-selectors";
import { getUserProfileData } from "../../store/user-process/user-process-selectors";
import Title from "antd/es/typography/Title";
import "./tour.scss"
import { CaretRightOutlined, EuroCircleFilled, FlagFilled, InboxOutlined, WarningOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import MyModal from "../../components/modal/modal";
import { showError, showInfo } from "../../services/notification";
import CreateTourForm from "../../components/forms/tour/create-tour/CreateTourForm";
import Paragraph from "antd/es/typography/Paragraph";
import EditTourDescriptionForm from "../../components/forms/tour/edit-tour-description/EditTourDescription";

const { Dragger } = Upload;

const TourPage = () => {
  const dispatch = useAppDispatch();
  const isToursListDataLoading = useAppSelector(getIsTourStepsListDataLoading);
  const tourStepsList = useAppSelector(getTourStepsList);
  const isSingleTourDataLoading = useAppSelector(getIsSingleTourDataLoading);
  const isSingleTourUpdating = useAppSelector(getIsSingleTourUpdating);
  const isSingleTourDescriptionUpdating = useAppSelector(getIsSingleTourDescriptionUpdating);
  const tour = useAppSelector(getTour);
  const updatedTourDescription = useAppSelector<string | null>(getUpdatedTourDescription);
  const profileData = useAppSelector(getUserProfileData);

  const [openAddTourStepDialog, setOpenAddTourStepDialog] = useState<boolean>(false);
  const [isTourStepCreating, setIsTourStepCreating] = useState<boolean>(false);
  const [tourNewPhotoUrl, setNewTourPhotoUrl] = useState<string | null>(null)
  const [isInChoosingTourPictureMode, setIsInChoosingTourPictureMode] = useState<boolean>(false);
  const [isInUploadingTourPictureMode, setIsInUploadingTourPictureMode] = useState<boolean>(false);
  const [openUpdateTourDialog, setOpenUpdateTourDialog] = useState<boolean>(false);
  const [isFileDragged, setIsFileDragged] = useState<boolean>(false);
  const [openEditDescriptionDialog, setOpenEditDescriptionDialog] = useState<boolean>(false);

  const isLoading = isToursListDataLoading || isSingleTourDataLoading;
  const tourName = tour ? tour.name : "Loading..."
  const canEditTour = tour && profileData && (tour.guide_id === profileData.guide_id || profileData.permissions.includes('EDIT_ALL_TOURS'))

  const { tourId } = useParams();

  const paginationConfig = {
    hideOnSinglePage: true,
  }

  const onAddNewTourStepButtonClick = () => {
    setOpenAddTourStepDialog(true);
  }

  const onAddTourStepCancelButtonClick = () => {
    setOpenAddTourStepDialog(false);
  }

  const onAddTourStepFormSubmit = (values: { name: string; tour_step_type: string; tour_step_id?: string;}) => {
    console.log(values);
    setIsTourStepCreating(true);
    setTimeout(() => {
      dispatch(createNewTourStepAction({requestData: {name: values.name, tour_step_type: values.tour_step_type, tour_step_id: values.tour_step_id}, tourId: tourId!})).unwrap()
        .then(
          () => {
            setOpenAddTourStepDialog(false);
            setIsTourStepCreating(false);
            showInfo("A tour step was successfully created");
            dispatch(fetchTourStepsListAction(tourId!));
          },
          () => {
            setIsTourStepCreating(false);
            showError("An error occurred while creating a new tour step");
          }
        )
    }, 2000);
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const columns: ColumnsType<TourStep> = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      render: (text, tourStep) => {
        const warnFlag = tourStep.data_validation_errors.length ? <><WarningOutlined className="tour-step-validation-error-icon" /><br />Error: {tourStep.data_validation_errors[0]}</> : ""
        return (
          <><Link to={`/tours/${tourId}/steps/${tourStep.tour_step_id}`} style={{ textDecoration: "none" }}>{text}</Link>{warnFlag}</>
        )
      },
    },
    {
      key: 'tour_step_type',
      title: 'Type',
      dataIndex: 'tour_step_type',
    },
  ];

  const statsData = [
    {
      title: 'Started (7 days)',
      counter: 175,
      inc: '+22%',
      inc_class: 'pos',
      icon: (<CaretRightOutlined />),
    },
    {
      title: 'Finished (7 days)',
      counter: 98,
      inc: '+22%',
      inc_class: 'pos',
      icon: (<FlagFilled />),
    },
    {
      title: 'Purchased (7 days)',
      counter: 88,
      inc: '+22%',
      inc_class: 'pos',
      icon: (<EuroCircleFilled />),
    },
  ];

  const draggerProps: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: "image/*",
    listType: "picture-card",
    maxCount: 1,
    customRequest: ({file, onSuccess, onError}) => {
      setIsFileDragged(false);
      const fmData = new FormData();
      fmData.append("file", file);
      dispatch(uploadTourImageAction({formData: fmData, tourId: tourId!})).unwrap()
        .then((newUrl: string) => {
          //onSuccess && onSuccess("Ok");
          setNewTourPhotoUrl(newUrl);
        }, () => {
          showError("Could not upload image. Please, try again later")
        })
        .catch((error) => {
          showError("Could not upload image. Please, try again later.")
        })
        .finally(() => {
          setIsInChoosingTourPictureMode(false);
          setIsInUploadingTourPictureMode(false);
        })
        setIsInUploadingTourPictureMode(true);
    },
  };

  useEffect(() => {
    dispatch(fetchTourStepsListAction(tourId!));
    dispatch(fetchSingleTourAction(tourId!));
  }, [dispatch, tourId])

  const tourStepTypes = TOUR_STEP_TYPES.map((tourStepType) => (
    {
      value: tourStepType,
      label: tourStepTypeAsStr(tourStepType)
    }
  ));

  const onUpdateTourFormSubmit = (
    values: { 
      name: string; 
      duration_in_minutes: number; 
      length_in_meters: number; 
      last_location_same: boolean; 
      sights: string; 
      available_from: Date; 
      available_until: Date; 
      active: boolean; 
      is_tour_paid: boolean;
      last_free_step_id: string | null;
      price_eur_cents: number;
      allow_leaving_tips: boolean;
    }) => {
    dispatch(updateTourAction({tourId: tourId!, tourData: {
      name: values.name,
      duration_in_minutes: values.duration_in_minutes,
      length_in_meters: values.length_in_meters,
      last_location_same: values.last_location_same,
      sights: values.sights.split(","),
      available_from: values.available_from || null,
      available_until: values.available_until || null,
      active: values.active,
      last_free_step_id: (values.is_tour_paid && values.last_free_step_id) ? values.last_free_step_id : null,
      price_eur_cents: values.is_tour_paid ? values.price_eur_cents : 0,
      allow_leaving_tips: values.allow_leaving_tips,
    }})).unwrap().then(() => {}, () => {
      showError("Could not update tour information")
    }).finally(() => {
      setOpenUpdateTourDialog(false);
    });
  }

  const onEditTourDescriptionFormSubnit = (values: {description: string;}) => {
    dispatch(updateTourDescriptionAction({tourId: tourId!, description: values.description})).unwrap()
    .finally(() => {
      setOpenEditDescriptionDialog(false);
    });
  }

  return (
    <>
      <h1>{tourName}</h1>
      <MyModal open={openUpdateTourDialog} onClose={() => setOpenUpdateTourDialog(false)}>
        <h2>Update this tour</h2>
        <CreateTourForm
          isDataUploading={isSingleTourUpdating}
          onSubmit={onUpdateTourFormSubmit}
          onCancel={() => setOpenUpdateTourDialog(false)}
          tourData={{
            id: tourId || "",
            name: tour ? tour.name : "",
            language: tour ? tour.language : "",
            duration_in_minutes: (tour && tour.duration_in_minutes) ? tour.duration_in_minutes : 0,
            length_in_meters: (tour && tour.length_in_meters) ? tour.length_in_meters : 0,
            last_location_same: tour && tour.last_location_same ? true : false,
            sights: tour && tour.sights ? tour.sights : [],
            available_from: tour && tour.available_from ? tour.available_from : null,
            available_until: tour && tour.available_until ? tour.available_until : null,
            active: tour && tour.active ? true : false,
            last_free_step_id: tour && tour.last_free_step_id ? tour.last_free_step_id : null,
            price_eur_cents: (tour && tour.price_eur_cents) ? tour.price_eur_cents : 0,
            allow_leaving_tips: tour && tour.allow_leaving_tips ? true : false,
            tour_steps: tourStepsList.map((tourStep) => ({tourStepId: tourStep.tour_step_id, tourStepName: tourStep.name, tourStepType: tourStep.tour_step_type}))
          }}
        />
      </MyModal>
      <MyModal open={openEditDescriptionDialog} onClose={() => setOpenEditDescriptionDialog(false)}>
        <h2>Update description</h2>
        <EditTourDescriptionForm
          isDataUploading={isSingleTourDescriptionUpdating}
          onSubmit={onEditTourDescriptionFormSubnit}
          onCancel={() => setOpenEditDescriptionDialog(false)}
          description={tour?.description || ""}
        />
      </MyModal>
      <MyModal open={openAddTourStepDialog} onClose={onAddTourStepCancelButtonClick}>
        <h2>Create a new tour step</h2>
        <Form
          {...formItemLayout}
          style={{ maxWidth: 600 }}
          onFinish={onAddTourStepFormSubmit}
          scrollToFirstError
        >
          <Form.Item label="Tour Step Id (Optional)" name="tour_step_id" rules={[{ required: false }]}>
            <Input type="text" disabled={isTourStepCreating}></Input>
          </Form.Item>
          <Form.Item label="Tour Step Name" name="name" rules={[{ required: true, message: 'Please, provide a name of a guide' }]}>
            <Input type="text" disabled={isTourStepCreating}></Input>
          </Form.Item>
          <Form.Item label="Step Type" name="tour_step_type" initialValue={TOUR_STEP_TYPES[0]} required={true}>
            <Select
              placeholder="Select tour step type"
              options={tourStepTypes}
              defaultValue={TOUR_STEP_TYPES[0]}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 8 }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={isTourStepCreating}>
                Submit
              </Button>
              <Button onClick={onAddTourStepCancelButtonClick} disabled={isTourStepCreating}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </MyModal>
      <div className="tour-page">
        { isLoading ? (
          <Spin/>
        ) : (
          <>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              {
                statsData.map((stats, index) => (
                  <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Card bordered={false} className="criclebox">
                      <div className="number-box">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={18}>
                            <span>{stats.title}</span>
                            <Title level={3}>{stats.counter}<small className={stats.inc_class}>{stats.inc}</small></Title>
                          </Col>
                          <Col xs={6}>
                            <div className="icon-box">{stats.icon}</div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))
              }
            </Row>
            <Card className="circlebox mb-20" bordered={false}>
              <Row gutter={[24, 10]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                  <Space direction="vertical">
                    <Title level={4}>Information</Title>
                    <span><b>ID: </b>{tour && tour.tour_id}</span>
                    <span><b>Name: </b>{tour && tour.name}</span>
                    <span><b>Main Language: </b>{tour && tour.language && tourLanguagesAsStr(tour.language)}</span>
                    <span><b>Number of steps: </b>{tourStepsList.length}</span>
                    <span><b>Duration in minutes: </b>{tour && tour.duration_in_minutes}</span>
                    <span><b>Length in meters: </b>{tour && tour.length_in_meters}</span>
                    <span><b>Is last location the same?: </b>{tour && (tour.last_location_same ? "Yes" : "No")}</span>
                    <span><b>Sights: </b>{tour && tour.sights && tour.sights.join(", ")}</span>
                    {tour && tour.available_from && (<span><b>Available from: </b>{tour.available_from.toISOString()}</span>) }
                    {tour && tour.available_until && (<span><b>Available until: </b>{tour.available_until.toISOString()}</span>) }
                    <span><b>Is active?: </b>{tour && (tour.active ? "Yes" : "No")}</span>
                    {tour && tour.price_eur_cents && tour.price_eur_cents > 0 ? (
                    <>
                    {tour && tour.last_free_step_name ? (<span><b>Last free step: </b>{tour.last_free_step_name}</span>) : ""}
                    <span><b>Price in euro-cents: </b>{tour.price_eur_cents}</span>
                    </>
                    ) : ""}
                    <span><b>Tips are allowed?: </b>{tour && (tour.allow_leaving_tips ? "Yes" : "No")}</span>
                    <Button onClick={() => setOpenUpdateTourDialog(true)}>Edit...</Button>
                  </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                  <Space direction="vertical">
                    <Title level={4}>Description</Title>
                    <Paragraph ellipsis={{ rows: 10, expandable: true, symbol: 'more' }}>
                      {(updatedTourDescription || tour?.description || "").split("\n").map((item, idx) => (<span key={idx}>{item}<br /></span>))}
                    </Paragraph>
                    <Button onClick={() => setOpenEditDescriptionDialog(true)}>Edit Description...</Button>
                  </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                  <Title level={4}>Tour image</Title>
                  <Space direction="vertical">
                  {isInUploadingTourPictureMode ? (<>Uploading...</>) : (
                  isInChoosingTourPictureMode ? (
                    <Dragger {...draggerProps} className={isFileDragged ? "file-dragger file-dragging" : "file-dragger"}>
                      <div onDragOver={() => {setIsFileDragged(true)}} onDragLeave={() => setIsFileDragged(false)}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag picture to this area to upload</p>
                      </div>
                    </Dragger>
                  ) :
                  (
                    tourNewPhotoUrl || (tour && tour.tour_photo_url) ? (
                      <Image
                        src={tourNewPhotoUrl || (tour && tour.tour_photo_url) || ""}
                        width={200}
                        preview={{
                          toolbarRender: (
                            _,
                            {
                              transform: { scale },
                              actions: {onZoomOut, onZoomIn}
                            }
                          ) => (
                            <Space size={12} className="page-view-tour-image-toolbar-wrapper">
                              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                            </Space>
                          )
                        }}
                      ></Image>
                    ) : (
                      <Alert
                        message="No image"
                        description="Please, upload a picture of the tour"
                        type="error"
                      />
                    )
                  ))}
                    <Button disabled={isInUploadingTourPictureMode} onClick={() => setIsInChoosingTourPictureMode(!isInChoosingTourPictureMode)}>
                      {isInChoosingTourPictureMode ? "Cancel choosing a picture" : "Change picture..."}
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                  <Space direction="vertical">
                    <Title level={4}>Publication status</Title>
                    <span>Last publishing time: {(new Date()).toLocaleString()}</span>
                    <Alert
                        description="Can not publish this tour - please, fix the issues first"
                        type="error"
                      />
                    <Button disabled={true}>Publish tour!</Button>
                  </Space>
                </Col>
              </Row>
            </Card>
            <Card className="criclebox" title="Tour Steps" extra={
              canEditTour ? (
                <>
                  <Button type="primary" onClick={onAddNewTourStepButtonClick}>Add a new step...</Button>
                </>
              ) : ""
            }>
              <div className="table-responsive">
                <Table rowKey="tour_step_id" loading={isLoading} columns={columns} pagination={paginationConfig} dataSource={tourStepsList}></Table>
              </div>
            </Card>
            </>
        ) }
      </div>
    </>
  )
}

export default TourPage;
