export enum NameSpace {
  User = 'USER',
  ProfileData = 'PROFILE_DATA',
  SingleTourData = 'SINGLE_TOUR_DATA',
  ToursListData = 'TOURS_LIST_DATA',
  TourStepsListData = 'TOUR_STEPS_LIST_DATA',
  TourStepData = 'TOUR_STEP_DATA',
  GuidesListData = 'GUIDES_LIST_DATA',
  ApplicationsListData = 'APPLICATIONS_LIST_DATA',
  ApplicationData = 'APPLICATION_DATA',
}
