import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstance } from "axios";
import { AppDispatch, AuthData, State, UserProfileData } from "../@types/store-types";
import { Token, saveToken } from "../services/token";
import { ApiRoute } from "../const/api-route";
import { Action } from "../const/action";
import { CreateNewApplicationLocationResponse, CreateNewApplicationRequestData, CreateNewApplicationResponse, CreateNewGuideRequestData, CreateNewGuideResponse, CreateNewTourRequestData, CreateNewTourResponse, CreateNewTourStepRequestData, CreateNewTourStepResponse, CreateTokenResponse, FetchApplicationsListResponse, FetchGuidesListResponse, FetchOneTourStepResponse, FetchSimgleApplicationResponse, FetchSingleTourResponse, FetchTourStepsListResponse, FetchToursListResponse, GetMyUserProfileResponse, UpdateApplicationLocationResponse, UpdateTourDescriptionResponse, UpdateTourRequestData, UpdateTourResponse, UpdateTourStepDestinationResponse, UpdateTourStepRequestData, UpdateTourStepResponse, UploadImageResponse, UploadTourStepAudioResponse } from "../@types/api-types";
import { Tour, TourId, TourStep, TourStepId } from "../@types/tour-types";
import { Guide, GuideId } from "../@types/guide-types";
import { Application, ApplicationId, ApplicationLocationId } from "../@types/application-types";

export const loadUserProfileAction = createAsyncThunk<
  UserProfileData,
  undefined,
  {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}
>(Action.LoadUserProfile,
  async (_arg, { extra: api }) => {
    const { data } = await api.get<GetMyUserProfileResponse>(ApiRoute.MyProfile);

    return data.data;
  }
);

export const loginAction = createAsyncThunk<
  Token,
  AuthData,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.UserLogIn,
    async({ email, password }, { extra: api }) => {
      const { data } = await api.post<CreateTokenResponse>(ApiRoute.Token, {email, password});
      saveToken(data.data);

      return data.data;
    }
  );

export const logoutAction = createAsyncThunk<
  void,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.UserLogOut,
    async (_arg, { extra: api }) => {
      await api.delete(ApiRoute.Token);
    }
  );

  export const fetchToursListAction = createAsyncThunk<
  Tour[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.FetchToursListAction,
    async (_arg, { extra: api }) => {
      const { data } = await api.get<FetchToursListResponse>(ApiRoute.Tours);

      return data.data;
    }
  );

export const fetchSingleTourAction = createAsyncThunk<
  Tour,
  TourId,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.FetchSingleTourAction,
    async (tourId, { extra: api }) => {
      const { data } = await api.get<FetchSingleTourResponse>(ApiRoute.Tour, { params: { tour_id: tourId }});

      return data.data;
    }
  );

export const createNewTourAction = createAsyncThunk<
  TourId,
  CreateNewTourRequestData,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.CreateNewTourAction,
    async (request_data, { extra: api }) => {
      const { data } = await api.post<CreateNewTourResponse>(ApiRoute.Tours, request_data);

      return data.data;
    }
  );

export const updateTourDescriptionAction = createAsyncThunk<
  string,
  {
    description: string,
    tourId: TourId
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.UpdateTourDescriptionAction,
    async ({description, tourId}, { extra: api }) => {
      const {data} = await api.put<UpdateTourDescriptionResponse>(ApiRoute.ToursDescription, description, {params: {tour_id: tourId}, headers: { "Content-Type": "text/plain" }});

      return data.data;
    }
  );

export const updateTourAction = createAsyncThunk<
  Tour,
  UpdateTourRequestData,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.UpdateTourAction,
    async ({tourId, tourData}, { extra: api }) => {
      const { data } = await api.put<UpdateTourResponse>(ApiRoute.Tours, tourData, {params: {tour_id: tourId}});

      return data.data;
    }
  );

export const uploadTourImageAction = createAsyncThunk<
  GuideId,
  {
    formData: FormData,
    tourId: TourId
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.UploadTourImageAction,
    async ({formData, tourId}, { extra: api }) => {
      const config = {
        params: { tour_id: tourId },
        headers: { "Content-Type": "multipart/form-data" },
      };
      const { data } = await api.post<UploadImageResponse>(ApiRoute.UploadTourImage, formData, config);

      return data.data;
    }
  );

export const fetchTourStepsListAction = createAsyncThunk<
  TourStep[],
  TourId,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.FetchTourStepsListAction,
    async (tourId, { extra: api }) => {
      const { data } = await api.get<FetchTourStepsListResponse>(`${ApiRoute.ToursSteps}`, { params: { tour_id: tourId }});

      return data.data;
    }
  );

export const createNewTourStepAction = createAsyncThunk<
  TourStepId,
  {
    requestData: CreateNewTourStepRequestData;
    tourId: TourId;
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.CreateNewTourStepAction,
    async ({requestData, tourId}, { extra: api }) => {
      const { data } = await api.post<CreateNewTourStepResponse>(ApiRoute.ToursSteps, requestData, { params: { tour_id: tourId }});

      return data.data;
    }
  );

export const fetchOneTourStepAction = createAsyncThunk<
  TourStep,
  {
    tourId: TourId,
    tourStepId: TourStepId,
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.FetchOneTourStepListAction,
    async ({tourId, tourStepId}, { extra: api }) => {
      const { data } = await api.get<FetchOneTourStepResponse>(`${ApiRoute.ToursSteps}`, { params: { tour_id: tourId, tour_step_id: tourStepId }});

      return data.data;
    }
  );

export const uploadTourStepAudioAction = createAsyncThunk<
  string,
  {
    tourId: TourId,
    tourStepId: TourStepId,
    audio: File,
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.UploadTourStepAudioAction,
    async ({ tourId, tourStepId, audio }, { extra: api }) => {
      const formData = new FormData();
      formData.append("file", audio);
      const config = {
        params: { tour_id: tourId, tour_step_id: tourStepId },
        headers: { "Content-Type": "multipart/form-data" },
      };

      const { data } = await api.post<UploadTourStepAudioResponse>(`${ApiRoute.UploadTourStepAudio}`, formData, config);

      return data.data;
    }
  );

export const updateTourStepDestinationAction = createAsyncThunk<
  string,
  {
    destination: string,
    tourId: TourId,
    tourStepId: TourStepId,
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.UpdateTourStepDestinationAction,
    async ({ destination, tourId, tourStepId }, { extra: api }) => {
      const config = {
        params: { tour_id: tourId, tour_step_id: tourStepId },
        headers: { "Content-Type": "text/plain" },
      };

      const { data } = await api.post<UpdateTourStepDestinationResponse>(`${ApiRoute.ToursStepsDestination}`, destination, config);

      return data.data;
    }
  );

export const updateTourStepAction = createAsyncThunk<
  TourStep,
  UpdateTourStepRequestData,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.UpdateTourStepAction,
    async ({tourId, tourStepId, tourStepData}, { extra: api }) => {
      const { data } = await api.put<UpdateTourStepResponse>(ApiRoute.ToursSteps, tourStepData, {params: {tour_id: tourId, tour_step_id: tourStepId}});

      return data.data;
    }
  );

export const fetchGuidesListAction = createAsyncThunk<
  Guide[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.FetchGuidesListAction,
    async (_arg, { extra: api }) => {
      const { data } = await api.get<FetchGuidesListResponse>(ApiRoute.Guides);

      return data.data;
    }
  );

export const createNewGuideAction = createAsyncThunk<
  GuideId,
  CreateNewGuideRequestData,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.CreateNewGuideAction,
    async (request_data, { extra: api }) => {
      const { data } = await api.post<CreateNewGuideResponse>(ApiRoute.Guides, request_data);

      return data.data;
    }
  );

export const fetchApplicationsListAction = createAsyncThunk<
  Application[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.FetchApplicationsListAction,
    async (_arg, { extra: api }) => {
      const { data } = await api.get<FetchApplicationsListResponse>(ApiRoute.Applications);

      return data.data;
    }
  );

export const fetchSingleApplicationAction = createAsyncThunk<
  Application,
  ApplicationId,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.FetchSingleApplicationAction,
    async (applicationId, { extra: api }) => {
      const { data } = await api.get<FetchSimgleApplicationResponse>(ApiRoute.Applications, {params: {application_id: applicationId}});

      return data.data;
    }
  );

  export const createNewApplicationAction = createAsyncThunk<
  ApplicationId,
  CreateNewApplicationRequestData,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.CreateNewApplicationAction,
    async (request_data, { extra: api }) => {
      const { data } = await api.post<CreateNewApplicationResponse>(ApiRoute.Applications, request_data);

      return data.data;
    }
  );
  
export const createNewApplicationLocationAction = createAsyncThunk<
  ApplicationId,
  {
    application_id: ApplicationId,
    name: string,
    parent_location_id: ApplicationLocationId
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.CreateNewApplicationLocationAction,
    async ({application_id, name, parent_location_id}, { extra: api }) => {
      const { data } = await api.post<CreateNewApplicationLocationResponse>(ApiRoute.ApplicationsLocation, {name: name}, {params: {application_id: application_id, parent_location_id: parent_location_id}});

      return data.data;
    }
  );

export const updateApplicationLocationAction = createAsyncThunk<
  ApplicationId,
  {
    application_id: ApplicationId,
    application_location_id: ApplicationLocationId,
    name?: string,
    parent_location_id?: ApplicationLocationId
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
  >(Action.UpdateApplicationLocationAction,
    async ({application_id, application_location_id, name, parent_location_id}, { extra: api }) => {
      const { data } = await api.patch<UpdateApplicationLocationResponse>(ApiRoute.ApplicationsLocation, {name, parent_location_id}, {params: {application_id: application_id, application_location_id: application_location_id}});

      return data.data;
    }
  );
