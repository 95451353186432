import { Button, Form, Input, InputNumber, Select, Space, Switch } from "antd";
import { TOUR_STEP_TYPES, TourStepId, TourStepType, tourStepTypeAsStr } from "../../../../@types/tour-types";
import "./edit-tour-step-form.scss"

export type EditTourStepFormData = {
  id: TourStepId,
  name: string,
  type: TourStepType,
  latitude?: number | null,
  longitude?: number | null,
  hide_photo_captions?: boolean
}

export type EditTourStepFormProps = {
  isDataUploading: boolean,
  onSubmit?: (data: EditTourStepFormData) => void,
  onCancel?: () => void,
  tourStepData?: EditTourStepFormData
};

const EditTourStepForm = ({onSubmit, isDataUploading = true, onCancel, tourStepData} : EditTourStepFormProps) => {
  const labelSpan = 9;

  const tourStepTypes = TOUR_STEP_TYPES.map((tourStepType) => (
    {
      value: tourStepType,
      label: tourStepTypeAsStr(tourStepType)
    }
  ));

  const tourStepTypeValue = (tourStepData && tourStepData.type) || TOUR_STEP_TYPES[0];
  const isGoToStepType = tourStepData && tourStepData.type === 'GO_TO';
  const isViewStepType = tourStepData && tourStepData.type === 'VIEW';

  const onFormSubmit = (values: {tour_step_id: TourStepId, name: string, tour_step_type: TourStepType, latitude?: number, longitude?: number, hide_photo_captions?: boolean}) => {
    onSubmit && onSubmit({
      id: values.tour_step_id,
      name: values.name,
      type: values.tour_step_type,
      latitude: values.latitude,
      longitude: values.longitude,
      hide_photo_captions: values.hide_photo_captions
    });
  }

  return (
    <Form onFinish={onFormSubmit} className="edit-tour-step-form" labelCol={{ span: labelSpan }}>
      {tourStepData && (
        <Form.Item label="Tour Step ID" name="tour_step_id" initialValue={tourStepData.id}>
          <Input type="text" disabled={true} value={tourStepData.id}></Input>
        </Form.Item>
      )}
      <Form.Item initialValue={tourStepData?.name} label="Tour Step Name" name="name" rules={[{ required: true, message: 'Please, give a name to your tour step' }]}>
        <Input type="text" disabled={isDataUploading}></Input>
      </Form.Item>
      <Form.Item label="Step Type" name="tour_step_type" initialValue={tourStepTypeValue} required={true}>
        <Select
          placeholder="Select tour step type"
          options={tourStepTypes}
          value={tourStepTypeValue}
          //defaultValue={tourStepTypeValue}
          disabled={!!tourStepData}
        />
      </Form.Item>
      {isGoToStepType ? (
      <>
        <Form.Item initialValue={tourStepData.latitude} label="Latitude" name="latitude" rules={[{ required: false }]}>
          <InputNumber disabled={isDataUploading} style={{ width: 200 }}></InputNumber>
        </Form.Item>
        <Form.Item initialValue={tourStepData.longitude} label="Longitude" name="longitude" rules={[{ required: false }]}>
          <InputNumber disabled={isDataUploading} style={{ width: 200 }}></InputNumber>
        </Form.Item>
      </>
      ) : ""}
      {isViewStepType ? (
      <Form.Item initialValue={tourStepData.hide_photo_captions} valuePropName="checked" label="Hide Photo Captions" name="hide_photo_captions">
        <Switch disabled={isDataUploading} checked={tourStepData.hide_photo_captions} />
      </Form.Item>
      ) : ""}
      <Form.Item wrapperCol={{ offset: labelSpan, span: 24 - labelSpan }}>
        <Space>
          <Button onClick={onCancel} disabled={isDataUploading}>Cancel</Button>
          {tourStepData ? (
            <Button loading={isDataUploading} htmlType="submit" type="primary">{isDataUploading? "Updating..." : "Update"}</Button>
          ) : (
            <Button loading={isDataUploading} htmlType="submit" type="primary">{isDataUploading? "Creating..." : "Create"}</Button>
          )}
        </Space>
      </Form.Item>          
    </Form>
  )
}

export default EditTourStepForm;
