import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getApplication, getIsApplicationDataLoading } from "../../store/application-data/application-data-selectors";
import { useEffect, useState } from "react";
import { createNewApplicationLocationAction, fetchSingleApplicationAction, updateApplicationLocationAction } from "../../store/api-actions";
import { ApplicationLocation, ApplicationLocationId } from "../../@types/application-types";
import { Button, Form, Input, Select, Space, Spin, Tree } from "antd";
import { DataNode, TreeProps } from "antd/es/tree";
import { Key } from "@mui/icons-material";
import MyModal from "../../components/modal/modal";

export type LocationTreeProps = {
  root_location: ApplicationLocation,
  draggable?: boolean,
  disabled?: boolean,
  canEditNodes?: boolean,
  canAddLocations?: boolean,
  isLocationCreating?: boolean,
  onLocationAdded?: (name: string, parent_location_id: ApplicationLocationId) => any
  onLocationDragged?: (location_id: ApplicationLocationId, parent_location_id: ApplicationLocationId) => any
};

const LocationTree = ({root_location, draggable=false, disabled=false, canEditNodes=true, canAddLocations: canAddLocations=true, isLocationCreating, onLocationAdded, onLocationDragged} : LocationTreeProps) => {
  const [openAddLocationDialog, setOpenAddLocationDialog] = useState<boolean>(false);

  const generateTreeData = (location: ApplicationLocation) : DataNode => {
    let current_node: DataNode = {
      title: location.name,
      key: location.location_id,
      children: []
    }
    location.children.forEach((child_location) => {
      current_node.children?.push(generateTreeData(child_location));
    });
    return current_node;
  };

  const generateSelectParentData = (location: ApplicationLocation): {label: string, value:string}[] => {
    let result = [
      {
        label: location.name,
        value: location.location_id
      }
    ];
    location.children.forEach((child_location: ApplicationLocation) => {
      result = [...result, ...generateSelectParentData(child_location)];
    });
    return result;
  };

  const titleRender = (nodeData: DataNode) => (<Space><>{nodeData.title}</>{canEditNodes ? <Button disabled={disabled}>Edit...</Button> : ""}</Space>)

  const onCreateNewLocationFormSubmit = (values: {name: string, parent_location: string}) => {
    onLocationAdded && onLocationAdded(values.name, values.parent_location);
  }

  const onDrop: TreeProps['onDrop'] = (info) => {
    onLocationDragged && onLocationDragged(info.dragNode.key.toString(), info.node.key.toString())
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
  <>
  <MyModal open={openAddLocationDialog} onClose={() => {setOpenAddLocationDialog(false)}}>
  <h2>Add a new location</h2>
  <Form
    {...formItemLayout}
    style={{ maxWidth: 600 }}
    onFinish={onCreateNewLocationFormSubmit}
    scrollToFirstError
  >
    <Form.Item label="Location Name" name="name" rules={[{ required: true, message: 'Please, provide a name of a location' }]}>
      <Input type="text" disabled={isLocationCreating}></Input>
    </Form.Item>
    <Form.Item label="Parent location" name="parent_location" rules={[{ required: true, message: 'Please, select a parent location' }]}>
      <Select disabled={isLocationCreating} placeholder="Select a parent location" options={generateSelectParentData(root_location)}></Select>
    </Form.Item>
    <Form.Item wrapperCol={{ span: 12, offset: 8 }}>
      <Space>
        <Button type="primary" htmlType="submit" loading={isLocationCreating}>
          Add
        </Button>
        <Button onClick={() => setOpenAddLocationDialog(false)} disabled={isLocationCreating}>Cancel</Button>
      </Space>
    </Form.Item>
  </Form>
  </MyModal>
  {canAddLocations ? <Button disabled={disabled} onClick={() => {setOpenAddLocationDialog(true)}}>Add location...</Button> : ""}
  <Tree defaultExpandAll onDrop={onDrop} disabled={disabled} draggable={draggable} autoExpandParent treeData={[generateTreeData(root_location)]} blockNode titleRender={titleRender} />
  </>
  )
}

const Application = () => {
  const dispatch = useAppDispatch();
  const { appId } = useParams();
  const isApplicationLoading = useAppSelector(getIsApplicationDataLoading);
  const application = useAppSelector(getApplication);

  const isLoading = isApplicationLoading || !application;

  const [isLocationCreating, setIsLocationCreating] = useState<boolean>(false);
  const [isLocationMoving, setIsLocationMoving] = useState<boolean>(false);

  const locationTreeDisabled = isLocationMoving;

  useEffect(() => {
    dispatch(fetchSingleApplicationAction(appId!));
  }, [dispatch]);

  const onCreateLocationFormSubmited = (name: string, parent_location_id: ApplicationLocationId) => {
    setIsLocationCreating(true);
    setTimeout(() => {
      dispatch(createNewApplicationLocationAction({application_id: application!.app_id, name, parent_location_id}))
      .unwrap()
      .then(() => {
        dispatch(fetchSingleApplicationAction(appId!));
      })
      .finally(() => {
        setIsLocationCreating(false);
      });
    }, 1000);
  };

  const onLocationMoved = (location_id: ApplicationLocationId, parent_location_id: ApplicationLocationId) => {
    setIsLocationMoving(true);
    setTimeout(() => {
      dispatch(updateApplicationLocationAction({application_id: application!.app_id, application_location_id: location_id, parent_location_id}))
      .unwrap()
      .then(() => {
        dispatch(fetchSingleApplicationAction(appId!));
      })
      .finally(() => {
        setIsLocationMoving(false);
      })
    }, 1000);
  }

  return (
  <div className="application-page">
    <h1>{isLoading ? "Loading..." : application.name}</h1>
    {isLoading ? <Spin /> : (
    <LocationTree
      draggable
      disabled={locationTreeDisabled}
      onLocationDragged={onLocationMoved}
      root_location={application.root_location}
      isLocationCreating={isLocationCreating}
      onLocationAdded={onCreateLocationFormSubmited}
    />
    )}
  </div>
  )
}

export default Application;
