import { createSlice } from "@reduxjs/toolkit";
import { ApplicationsListData } from "../../@types/store-types";
import { NameSpace } from "../../const/name-space";
import { fetchApplicationsListAction } from "../api-actions";

const initialState: ApplicationsListData = {
  applicationsList: [],
  isApplicationssListDataLoading: false,
};

export const applicationsListData = createSlice({
  name: NameSpace.ApplicationsListData,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchApplicationsListAction.pending, (state) => {
        state.applicationsList = [];
        state.isApplicationssListDataLoading = true;
      })
      .addCase(fetchApplicationsListAction.fulfilled, (state, action) => {
        state.applicationsList = action.payload;
        state.isApplicationssListDataLoading = false;
      })
      .addCase(fetchApplicationsListAction.rejected, (state) => {
        state.isApplicationssListDataLoading = false;
      });
  }
});
