import { Button, Form, Space } from "antd";
import "./edit-tour-destination.scss"
import TextArea from "antd/es/input/TextArea";

export type EditTourDestinationProps = {
  isDataUploading: boolean,
  onSubmit?: (newDestination: string) => void,
  onCancel?: () => void,
  destination: string
};

const EditTourDestinationForm = ({onSubmit, isDataUploading = true, onCancel, destination} : EditTourDestinationProps) => {
  return (
    <Form onFinish={(values: {destination: string}) => {onSubmit && onSubmit(values.destination)}} className="create-update-tour-step-destination-form">
      <Form.Item initialValue={destination} label="Destination" name="destination">
        <TextArea disabled={isDataUploading} autoSize={{ minRows: 3, maxRows: 10 }}></TextArea>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button onClick={onCancel} disabled={isDataUploading}>Cancel</Button>
          <Button loading={isDataUploading} htmlType="submit" type="primary">{isDataUploading? "Updating..." : "Update"}</Button>
        </Space>
      </Form.Item>          
    </Form>
  )
};

export default EditTourDestinationForm;
