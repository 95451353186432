import { Alert, Button, DatePicker, Form, Input, InputNumber, Select, Space, Switch } from "antd";
import { TOUR_LANGUAGES, TourId, TourLanguage, TourStepId, TourStepType, tourLanguagesAsStr } from "../../../../@types/tour-types";
import "./create-tour-form.scss"
import { useState } from "react";

export type TourStepData = {
  tourStepId: TourStepId,
  tourStepName: string,
  tourStepType: TourStepType,
}

export type TourData = {
  id: TourId,
  name: string,
  language: TourLanguage,
  duration_in_minutes: number,
  length_in_meters: number,
  last_location_same: boolean,
  sights: string[],
  available_from: Date | null,
  available_until: Date | null,
  active: boolean,
  last_free_step_id: number | null,
  price_eur_cents: number,
  allow_leaving_tips: boolean,
  tour_steps: TourStepData[],
}

export type CreateTourFormProps = {
  isDataUploading: boolean,
  onSubmit?: (values: any) => void,
  onCancel?: () => void,
  tourData?: TourData
};

const CreateTourForm = ({onSubmit, isDataUploading = true, onCancel, tourData} : CreateTourFormProps) => {
  const [isTourPaid, setIsTourPaid] = useState<boolean>(!!(tourData && tourData.price_eur_cents && tourData.last_free_step_id));

  const tourStepTypes = TOUR_LANGUAGES.map((tourLanguage) => (
    {
      value: tourLanguage,
      label: tourLanguagesAsStr(tourLanguage)
    }
  ));

  const labelSpan = 9;

  const stepsData = tourData && tourData.tour_steps.map((stepData) => (
    {
      value: stepData.tourStepId,
      label: `${stepData.tourStepName} (${stepData.tourStepType})`
    }
  ));

  const priceInCentsValue = !isTourPaid ? 0 : (tourData ? tourData.price_eur_cents : 0);

  return (
    <Form onFinish={onSubmit} className="create-update-tour-form" labelCol={{ span: labelSpan }}>
      {!tourData && (
        <Alert
          description="Please, choose Main Language value wisely - you won't be able to edit this property after creating a tour"
          type="warning"
          className="alert"
        />
    )}
      {tourData && (
        <Form.Item label="Tour ID">
          <Input type="text" disabled={true} value={tourData.id}></Input>
        </Form.Item>
      )}
      <Form.Item initialValue={tourData?.name} label="Tour Name" name="name" rules={[{ required: true, message: 'Please, give a name to your tour' }]}>
        <Input type="text" disabled={isDataUploading}></Input>
      </Form.Item>
      <Form.Item initialValue={tourData ? tourData.language : null} label="Main tour language" name="language" rules={[{ required: true, message: 'Please, select the main tour language' }]}>
        <Select
          disabled={!!tourData || isDataUploading}
          placeholder="Select main tour language"
          options={tourStepTypes}
        />
      </Form.Item>
      {tourData && (<>
      <Form.Item initialValue={tourData.duration_in_minutes || 0} label="Duration in minutes" name="duration_in_minutes">
        <InputNumber disabled={isDataUploading} min={0}></InputNumber>
      </Form.Item>
      <Form.Item initialValue={tourData.length_in_meters || 0} label="Length in meters" name="length_in_meters">
        <InputNumber disabled={isDataUploading} min={0}></InputNumber>
      </Form.Item>
      <Form.Item initialValue={tourData.last_location_same} valuePropName="checked" label="Is the last location same" name="last_location_same">
        <Switch disabled={isDataUploading} checked={tourData.last_location_same} />
      </Form.Item>
      <Form.Item initialValue={tourData.sights.join(", ")} label="Sights (comma-separated)" name="sights">
        <Input type="text" disabled={isDataUploading}></Input>
      </Form.Item>
      <Form.Item initialValue={tourData.available_from || null} label="Available From (UTC)" name="available_from">
        <DatePicker disabled={isDataUploading} showTime={true} />
      </Form.Item>
      <Form.Item initialValue={tourData.available_until || null} label="Available Until (UTC)" name="available_until">
        <DatePicker disabled={isDataUploading} showTime={true} />
      </Form.Item>
      <Form.Item initialValue={tourData.active} valuePropName="checked" label="Is tour active" name="active">
        <Switch disabled={isDataUploading} checked={tourData.active} />
      </Form.Item>
      <Form.Item initialValue={isTourPaid} valuePropName="checked" label="Is tour paid" name="is_tour_paid">
        <Switch disabled={isDataUploading} checked={isTourPaid} onChange={() => {setIsTourPaid(!isTourPaid);}} />
      </Form.Item>
      <Form.Item hidden={!isTourPaid} label="The last free step" name="last_free_step_id">
        <Select
          placeholder="Select a tour step"
          options={stepsData}
          disabled={isDataUploading}
        />
      </Form.Item>
      <Form.Item hidden={!isTourPaid} initialValue={priceInCentsValue} label="Price in EUR cents" name="price_eur_cents">
        <InputNumber disabled={isDataUploading} min={0} value={priceInCentsValue}></InputNumber>
      </Form.Item>
      <Form.Item initialValue={tourData.allow_leaving_tips} valuePropName="checked" label="Allow leaving tips" name="allow_leaving_tips">
        <Switch disabled={isDataUploading} checked={tourData.allow_leaving_tips} />
      </Form.Item>
      </>)}
      <Form.Item wrapperCol={{ offset: labelSpan, span: 24 - labelSpan }}>
        <Space>
          <Button onClick={onCancel} disabled={isDataUploading}>Cancel</Button>
          {tourData ? (
            <Button loading={isDataUploading} htmlType="submit" type="primary">{isDataUploading? "Updating..." : "Update"}</Button>
          ) : (
            <Button loading={isDataUploading} htmlType="submit" type="primary">{isDataUploading? "Creating..." : "Create"}</Button>
          )}
        </Space>
      </Form.Item>          
    </Form>
  );
};

export default CreateTourForm;
