import { createSlice } from '@reduxjs/toolkit';

import { loadUserProfileAction, loginAction, logoutAction } from '../api-actions';

import { AuthorizationStatus } from '../../const/authorization-status';
import { NameSpace } from '../../const/name-space';

import { UserAuthStateData, UserProfileData } from '../../@types/store-types';

const initialState: UserAuthStateData = {
  authorizationStatus: AuthorizationStatus.Unknown,
  userProfile: {} as UserProfileData,
  isLoginLoading: false
};

export const userProcessData = createSlice({
  name: NameSpace.User,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadUserProfileAction.fulfilled, (state, action) => {
        state.authorizationStatus = AuthorizationStatus.Auth;
        state.userProfile = action.payload;
      })
      .addCase(loadUserProfileAction.rejected, (state) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth;
      })
      .addCase(loginAction.fulfilled, (state) => {
        state.authorizationStatus = AuthorizationStatus.Auth;
        state.isLoginLoading = false;
      })
      .addCase(loginAction.rejected, (state) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth;
        state.isLoginLoading = false;
      })
      .addCase(loginAction.pending, (state) => {
        state.isLoginLoading = true;
      })
      .addCase(logoutAction.fulfilled, (state) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth;
        state.isLoginLoading = false;
      })
      .addCase(logoutAction.pending, (state) => {
        state.isLoginLoading = true;
      });
  }
});
