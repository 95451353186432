import Navbar from "../navbar/Navbar"
import Sidebar from "../sidebar/Sidebar"
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import "./page.scss"
import { Drawer, Layout, Affix } from "antd";
import { ReactNode, useState } from "react";

const { Header: AntHeader, Content, Sider } = Layout;

type PageProps = {
  breadcrumb?: ReactNode;
  children?: ReactNode;
  title?: string;
  helpLinkHref?: string;
};

const Page = ({breadcrumb, children, title, helpLinkHref}: PageProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const helpButton = (
    helpLinkHref ? <a href={helpLinkHref} target="_blank" rel="noreferrer"><div className="helpLink"><QuestionMarkIcon className="icon" /> Help</div></a> : ""
  )

  return (
    <Layout className="layout-admin">
      <Drawer
        title={false}
        placement="left"
        closable={false}
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        key="left"
        width={250}
        className="drawer-sidebar"
      >
        <Layout
          className="layout-dashboard"
        >
          <Sider
            trigger={null}
            width={250}
            theme="light"
            className="layout-sider-menu"
          >
            <Sidebar onLinkClick={() => setDrawerOpen(false)} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        width={250}
        theme="light"
        className="layout-sider-menu"
      >
        <Sidebar />
      </Sider>
      <Layout>
        <Affix>
          <AntHeader className="ant-header-fixed">
            <Navbar onSidebarMenuClick={() => setDrawerOpen(!drawerOpen)}/>
          </AntHeader>
        </Affix>
        <Content className="content-ant">
          {breadcrumb ? <div className="breadcrumb">{breadcrumb}</div> : ""}
          {title ? <div className="title"><h1>{title}</h1>{helpButton}</div> : ""}
          <div className="page-content">
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default Page;
