import "./home.scss"

const HomePage = () => {
  return (
    <>
      <div className="homePage">
        Test Page
      </div>
    </>
  )
}
 
export default HomePage
