import "./navbar.scss";
//import { useAppSelector } from "../../hooks";
//import { getUserProfileData } from "../../store/user-process/user-process-selectors";
import { IdcardFilled, MenuOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";

type NavbarProps = {
  onSidebarMenuClick?: () => void;
};

const Navbar = ({ onSidebarMenuClick }: NavbarProps) => {
  // const userProfile = useAppSelector(getUserProfileData);

  const _onSidebarMenuClick = () => {
    onSidebarMenuClick && onSidebarMenuClick();
  }
  
  return (
    <div className="navbar">
      <Row className="header-control">
        <Col flex={5}>
          <Button type="link" onClick={_onSidebarMenuClick} className="sidebar-menu-button">
            <MenuOutlined />
          </Button>
          <Input
            className="header-search"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            style={{width: 200}}
          />
        </Col>
        <Col flex={"auto"}></Col>
        <Col flex={2} className="right-cell">
          <Button type="link">
            <IdcardFilled />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Navbar;
