import { createSlice } from "@reduxjs/toolkit";
import { TourData } from "../../@types/store-types";
import { NameSpace } from "../../const/name-space";
import { createNewTourAction, fetchSingleTourAction, updateTourAction, updateTourDescriptionAction } from "../api-actions";


const initialState: TourData = {
  tour: null,
  updatedTourDescription: null,
  isSingleTourDataLoading: false,
  isSingleTourCreating: false,
  isSingleTourUpdating: false,
  isSingleTourDescriptionUpdating: false,
};

export const singleTourData = createSlice({
  name: NameSpace.SingleTourData,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSingleTourAction.pending, (state) => {
        state.isSingleTourDataLoading = true;
      })
      .addCase(fetchSingleTourAction.fulfilled, (state, action) => {
        state.tour = action.payload;
        state.isSingleTourDataLoading = false;
      })
      .addCase(fetchSingleTourAction.rejected, (state) => {
        state.isSingleTourDataLoading = false;
      })
      .addCase(createNewTourAction.pending, (state) => {
        state.isSingleTourCreating = true;
      })
      .addCase(createNewTourAction.fulfilled, (state) => {
        state.isSingleTourCreating = false;
      })
      .addCase(createNewTourAction.rejected, (state) => {
        state.isSingleTourCreating = false;
      })
      .addCase(updateTourAction.pending, (state) => {
        state.isSingleTourUpdating = true;
      })
      .addCase(updateTourAction.fulfilled, (state, action) => {
        state.isSingleTourUpdating = false;
        state.tour = action.payload;
      })
      .addCase(updateTourAction.rejected, (state) => {
        state.isSingleTourUpdating = false;
      })
      .addCase(updateTourDescriptionAction.pending, (state) => {
        state.isSingleTourDescriptionUpdating = true;
      })
      .addCase(updateTourDescriptionAction.fulfilled, (state, action) => {
        state.isSingleTourDescriptionUpdating = false;
        state.updatedTourDescription = action.payload;
      })
      .addCase(updateTourDescriptionAction.rejected, (state) => {
        state.isSingleTourDescriptionUpdating = false;
      })
  }
});
