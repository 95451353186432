import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import { StatusCodes } from 'http-status-codes';
import { getToken } from './token';
import { showError } from './notification';

export const LOCAL_API_URL = 'http://localhost:7370/api/v1';
export const PRODUCTION_API_URL = 'https://admin.walkwith.fun/api/v1';

const isLocalRuntime = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
export const API_URL = isLocalRuntime ? LOCAL_API_URL : PRODUCTION_API_URL;

const REQUEST_TIMEOUT = 5000;

const statusErrors = new Set([
  StatusCodes.BAD_REQUEST,
  StatusCodes.NOT_FOUND,
  StatusCodes.FORBIDDEN,
  StatusCodes.GATEWAY_TIMEOUT,
  StatusCodes.INTERNAL_SERVER_ERROR,
  StatusCodes.METHOD_NOT_ALLOWED,
  StatusCodes.REQUEST_TIMEOUT,
  StatusCodes.UNAUTHORIZED,
]);

const shouldDisplayError = (response: AxiosResponse) => statusErrors.has(response.status);

export const createAPI = (): AxiosInstance => {
  const api = axios.create({
    baseURL: API_URL,
    timeout: REQUEST_TIMEOUT
  });

  api.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = getToken();

      if (token && config.headers) {
        config.headers['Authorization'] = 'Bearer ' + token;
        if (!config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json';
        }
      }
      return config;
    }
  );

  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError<{error: {description: string}}>) => {
      if(error.response && shouldDisplayError(error.response)) {
        const errorMsg = error.response.data && error.response.data.error && error.response.data.error.description
          ? error.response.data.error.description : `ERROR: ${error.response.status.toString()}`
        showError(errorMsg);
      }

      throw error;
    }
  );

  return api;
};
